<template>
<div class="container">
    <div class="header">
        <div class="title">주요이벤트현황</div>
    </div>
    <!-- <div class="subTitle">
        <div class="spot">SPOT/DEVICE</div>
        <div class="content">CONTENT</div>
        <div class="date">DATE</div>
        <div class="remove">알람종료</div>
    </div> -->
    <div class="eventWrapper" v-bind:style="{height:this.event_height + 'px'}">
        <div class="event" v-for="(data, index) in sortedCreatedAt(eventTable.data)" :key="index" @click="onClickRow(data)">
            <div class="spot">
                <div class="device_img"><img :src="findImage(data)" width=30 height=30 /></div>
                <div class="device_title">
                    <div class="device_address">{{ getSiteName(data.site) }}</div>
                    <div class="device_name">{{ data.device_name }}</div>
                </div>
            </div>
            <div class="content">
                <div v-html="contentFormatter(data)"></div>
            </div>
            <div class="date">
                {{ dateFormatter(data.created_at) }}
            </div>
            <div class="remove" @click="onCloseEvent(data)">
                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="17" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
            </div>
        </div>
    </div>
    <Confirm ref="confirmDialog" @hide="hideModal" />

    <Notify ref="notifyDialog" />
</div>
</template>

<script>
import { IMAGES } from "@/constants/constant";
import utils from "@/services/utils";
import moment from "moment";
import Event from "@/views/device/Event";

export default {
name: "EventLog",
extends: Event,
props: {
    device_guid : {
    type: String,
    default: undefined
    },
    prefix: {
    type: String,
    default: 'srds_'
    },
    event_height: {
    type: String,
    default: "-1"
    }
},
methods: {
    onCloseEvent(data) {
    // console.log('Event::onCloseEvent selected_row:', JSON.stringify(this.selected_row,null,2))
    this.selected_row = data;
    
    if (data === null) return;
    
    let message =
        "이벤트 : " +
        data.device_name + " 알림을 종료 하시겠습니까?";
        this.$refs.confirmDialog.show(
        message,
        "이벤트 종료",
        undefined,
        "delete"
    );
    },
    getEventList() {
    var self = this;
    if (this.event_height != -1) {
        this.eventTable.options.height = this.event_heights
    }
    var data = {
        is_closed: 0,
        device_guid: this.device_guisd,
    };
    // console.log('Event::getEventList data:', JSON.stringify(data))
    this.$store
        .dispatch("rest/method", {
        model: "events",
        method: "getAlarmList",
        data: data,
        })
        .then((res) => {
        self.eventTable.data = [];
        res.forEach(function (row, index) {
            if (row.service_type === "srds") {
            self.eventTable.data.push({
                created_at: row.created_at,
                description: row.description,
                service_type: row.service_type,
                sensor_type: row.sensor_type,
                sensor_id: row.sensor_id,
                is_closed: row.is_closed,
                level: row.level,
                device_guid: row.device_guid,
                device_name: _.get(row, "device.name"),
                device_address: _.get(row, "device.address"),
                site: _.get(row, "device.site_guid"),
                guid: row.guid,
                sensor_value: row.sensor_value,
                pre_angle_change: row.standard,
                angle_diff: row.angle_diff,
                battery: row.battery,
            });
            }
        });
        self.$emit("update", self.eventTable.data);
        })
        .catch((err) => {
        console.log("queryEvent event: ", err.toString());
        this.$refs.notifyDialog.show(err.toString());
        });
    },
    sortedCreatedAt() {
    let sortedData = _.sortBy(this.eventTable.data, [ (o) => { return o.created_at }]).reverse()
    return sortedData
    },
    findImage(data) {
    if(data.level === 3 && data.description === '위험') {
        return IMAGES['DW'].name
    } else if(data.level === 2 && data.description === '주의') {
        return IMAGES['DC'].name 
    } else {
        return
    }
    },
    dateFormatter(data) {
    return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },

    contentFormatter(data) {
    let content = data

    let thresholds = utils.getThresholds();

    const level0 = _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0
    const level2 = _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3
    const level3 = _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5


    let current_value = Object.entries(content.sensor_value).map(ele => {
        let val = ele[1]
        return val
    })

    let diff = Object.entries(content.angle_diff).map(ele => { 
        var val = ele[1]
        return val; 
    })

    let result = ''

    let angle_diff_x = "<span>" + data.angle_diff.x_value + "</span>"
    let angle_diff_y = "<span>" + data.angle_diff.y_value + "</span>"

    for(var i = 0 ; i < current_value.length - 1; i++) {
        if(diff[i] > level3 || diff[i] <= (level3*(-1))) {
        // 양과 음의 방향으로 level3(기울기)보다 높아 지면 위험
            angle_diff_x = "<span style='color: #E50000; font-weight:bold;'>" + "주의" + "</span>"
            angle_diff_y = "<span style='color: #E50000; font-weight:bold;'>" + "주의" + "</span>"
        } else if(diff[i] > level2 || diff[i] <= (level2*(-1))) {
        // 주의
            angle_diff_x = "<span style='color: #FF8C00; font-weight:bold;'>" + "위험" + "</span>"
            angle_diff_y = "<span style='color: #FF8C00; font-weight:bold;'>" + "위험" + "</span>"
        } 
    }
    result += data.angle_diff.x_value < 0 ? "이미지 분석결과" + "( " + angle_diff_x + " )" + "<br />" : "이미지 분석결과 " + "( " + angle_diff_x + " )" + "<br />"
    // result += data.angle_diff.y_value < 0 ? "후방" + "( " + angle_diff_y + "º )" + "도 기울어짐" + "<br />" : "전방 " + "( " + angle_diff_y + "º )" + "도 기울어짐" + "<br />"

    return result
    }
},
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'GmarketSansMedium', sans-serif;
}
.container {
    margin: 0;
    padding: 0;
    .header {
        display: flex;
        margin: 4px 16px;
        .title {
            width: 700px;
            font-size: 26px;
        }
    }
    .subTitle {
        display: flex;
        font-size: 12px;
        padding: 12px 16px;
        .spot {
            width: 250px;
        }
        .content {
            width: 300px;
        }
        .date {
            width: 230px;
        }
        .remove {
            width: 65px;
        }
    }
    .eventWrapper {
        border-radius: 16px;
        width: 100%;
        height: 300px;
        overflow: auto;
        .event {
            background-color: #fff;
            display: flex;
            height: 58px;
            border-top: 1px dotted gray;
            padding: 12px 16px;
            align-items: center;
            border-radius: 0, 16px;
            .spot {
                // background-color: aquamarine;
                display: flex;
                width: 250px;
                height: 39px;
                align-items: center;
                .device_title {
                    a {
                    font-size: 12px;
                    display: block;
                    color: gray
                    }
                    margin-left: 10px;
                    .device_address {
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .device_name {
                        font-size: 12px;
                    }
                }
            }
            .content {
                // height: 39px;
                width: 300px;
            }
            
            .date {
                font-weight: 500;
                height: 39px;
                line-height: 32px;
                width: 230px;
                font-size: 13px;

            }
            .remove {
                cursor: pointer;
                text-align: center;
                width: 55px;
            }
        }
    }
}
.eventWrapper::-webkit-scrollbar {
background-color: #fff;
width: 10px;
}
.eventWrapper::-webkit-scrollbar-track {
background-color: #fff;
}
.eventWrapper::-webkit-scrollbar-thumb {
background-color: #babac0;
border-radius: 16px;
border: 4xp solid #fff;
}

@media screen and (max-width: 400px){
    .container {
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    .header {
        display: flex;
        margin: 5px 16px;
        .title {
            width: 700px;
            font-size: 24px;
        }
    }
    .subTitle {
        display: flex;
        font-size: 12px;
        padding: 12px 16px;
        .spot {
            width: 250px;
        }
        .content {
            width: 300px;
        }
        .date {
            width: 230px;
        }
        .remove {
            width: 65px;
        }
    }
    .eventWrapper {
        width: 100%;
        height: 300px;
        overflow: auto;
        .event {
            background-color: #fff;
            display: flex;
            height: 74px;
            border-top: 1px dotted gray;
            padding: 10px 10px;
            align-items: center;
            .spot {
                // background-color: aquamarine;
                display: flex;
                width: 300px;
                height: 40px;
                align-items: center;
                .device_title {
                    a {
                        font-size: 10px;
                        display: block;
                        color: gray
                    }
                    margin-left: 10px;
                    .device_address {
                        font-weight: 700;
                        font-size: 12px;
                    }
                    .device_name {
                        font-size: 11px;
                    }
                }
            }
            .content {
                font-size: 12px;
                height: 39px;
                width: 420px;
            }
            
            .date {
                font-size: 12px;
                font-weight: 500;
                height: 39px;
                width: 220px;
                text-align: center;
            }
            .remove {
                cursor: pointer;
                text-align: center;
                width: 50px;
            }
        }
    }
}
}

@media (min-width: 401px) and (max-width: 540px){
    .container {
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    .header {
        display: flex;
        margin: 5px 16px;
        .title {
            width: 700px;
            font-size: 24px;
        }
    }
    .subTitle {
        display: flex;
        font-size: 12px;
        padding: 12px 16px;
        .spot {
            width: 250px;
        }
        .content {
            width: 300px;
        }
        .date {
            width: 230px;
        }
        .remove {
            width: 65px;
        }
    }
    .eventWrapper {
        width: 100%;
        height: 300px;
        overflow: auto;
        .event {
            background-color: #fff;
            display: flex;
            height: 74px;
            border-top: 1px dotted gray;
            padding: 10px 10px;
            align-items: center;
            .spot {
                // background-color: aquamarine;
                display: flex;
                width: 290px;
                height: 40px;
                align-items: center;
                margin-right: 1%;
                .device_title {
                    a {
                        font-size: 10px;
                        display: block;
                        color: gray
                    }
                    margin-left: 10px;
                    .device_address {
                        font-weight: 700;
                        font-size: 13px;
                    }
                    .device_name {
                        font-size: 12px;
                    }
                }
            }
            .content {
                font-size: 14px;
                height: 39px;
                width: 470px;
            }
            
            .date {
                font-size: 12.8px;
                font-weight: 500;
                height: 39px;
                width: 220px;
                text-align: center;
                margin-right: 1%;
                line-height: 20px;

            }
            .remove {
                cursor: pointer;
                text-align: center;
                width: 44px;
            }
        }
    }
}
}
</style>