<template>
  <div>
    <CRow>
      <CCol col="12" xl="7">
        <Summation ref="summationView" @click="onClick" />
        <CCard>
          <Device ref="deviceView" @change="onChangedSite" prefix="srds_" />
          <!-- <h2>Device.vue</h2> -->
        </CCard>
      </CCol>
      <CCol col="12" xl="5">
        <CRow>
          <CCol col="12" xl="6" sm="6">
            <CCard>
              <Inspection ref="weatherView2"/>
            </CCard>
          </CCol>
          <CCol col="12" xl="6" sm="6">
            <CCard><Weather ref="weatherView" /></CCard>
            <!-- <Weather3 ref="weatherView" /> -->
          </CCol>
        </CRow>
        <CCard>
          <GoogleMap
            :sensor_type="8"
            :sensor_level="1"
            ref="googleMap"
            style="
              height: 100%;
              padding-top: 2%;
              padding-bottom: 2%;
              padding-left: 2%;
              padding-right: 2%;
            "
          />
        </CCard>
        <CCard>
          <EventLog
            ref="eventView"
            event_height="230" 
            @update="updateSummation"
            @click="onClickEvent"
            prefix="srds_"
          />
        </CCard>
      </CCol>
    </CRow>
    <EventDialog ref="eventDialog" @click="onClickEvent" />
  </div>
</template>

<script>
import GoogleMap from "@/views/srds/GoogleMap";

import Summation from "@/views/pwdm/Summation";
import EventDialog from "@/views/srds/EventDialog";
import EventLog from "@/views/pwdm/EventLog";
import Device from "@/views/srds/Device";
import Dashboard from "@/views/dashboard/Dashboard.vue";


// renewal
import Weather from '@/views/srds/Weather.vue'
import Inspection from '@/views/srds/Inspection.vue'


export default {
  name: "SRDSBoard",
  extends: Dashboard,
  components: {
    GoogleMap,
    EventDialog,
    Weather,
    Summation,
    Device,
    EventLog,
    Inspection
  },
  methods: {
    onClick(item) {
      // console.log('Dashboard::onClick Summation click item:', JSON.stringify(item,null,2))
      if (item.val != 0 && item.key != "DN") {
        var eventTable = this.$refs.eventView.getTable();
        this.$refs.eventDialog.show(item, eventTable.data);
      }
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
            or:[
              {
                service_type: "srds",
              },
              {
                service_type: "srds.dual",
              }
            ]
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          },
        });
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        })
        .then(() => {
          var eventTable = this.$refs.eventView.getTable();
          eventTable.data.forEach((event) => {
            this.$refs.deviceView.setMarkerImage(event);
          });
        });
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 300;
  font-style: normal;
  src: url("../../../public/css/fonts/NotoSans-DemiLight.eot?") format("eot"),
    url("../../../public/css/fonts/NotoSans-DemiLight.otf") format("opentype"),
    url("../../../public/css/fonts/NotoSans-DemiLight.woff") format("woff"),
    url("../../../public/css/fonts/NotoSans-DemiLight.woff2") format("woff2");
}

* {
  font-family: 'Noto Sans Korean', sans-serif;
}

.card {
  border-radius: 12px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
          0 1px 1px rgba(0,0,0,0.15), 
          0 3px 3px rgba(0,0,0,0.15), 
          0 6px 6px rgba(0,0,0,0.15);
}

</style>